import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate, Link } from 'react-router-dom';
import AWS from 'aws-sdk';

function AdminPage() {
  const navigate = useNavigate();

  // For display/logging
  const [userEmail, setUserEmail] = useState('');

  // Current capacity from the "get capacity" Lambda
  const [currentCapacity, setCurrentCapacity] = useState(null);

  // The new capacity the user selects to update
  const [selectedCapacity, setSelectedCapacity] = useState('');

  // 1) On page load, get user email and fetch current capacity
  useEffect(() => {
    // Fetch user email
    Auth.currentAuthenticatedUser()
      .then(currentUser => {
        setUserEmail(currentUser.attributes?.email || '');
      })
      .catch(err => {
        console.error('Error fetching user:', err);
      });

    // Fetch current capacity via separate Lambda
    fetchCurrentCapacity();
  }, []);

  // ------------------------------
  // Fetch the current desired capacity
  // ------------------------------
  const fetchCurrentCapacity = async () => {
    try {
      // Configure AWS region and credentials
      AWS.config.region = 'ap-southeast-2'; // adjust as needed
      const credentials = await Auth.currentCredentials();
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'ap-southeast-2:dab71613-f090-4601-b430-2dd3a76aec98',
        Logins: {
          'cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_G2MokH52O':
            (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
      });

      const lambda = new AWS.Lambda();

      // Invoke the "get" Lambda
      const params = {
        FunctionName: 'AsseticOpsLambdasStack-production-get_desired_capacity', 
        InvocationType: 'RequestResponse',
        Payload: JSON.stringify({}),
      };

      AWS.config.credentials.get(async (error) => {
        if (error) {
          console.error('Error retrieving credentials:', error);
          return;
        }
        const result = await lambda.invoke(params).promise();
        const payload = JSON.parse(result.Payload || '{}');

        if (payload.statusCode === 200) {
          // e.g. { "desiredCapacity": 7 } in payload.body
          const body = JSON.parse(payload.body || '{}');
          setCurrentCapacity(body.desiredCapacity);
        } else {
          console.error('Failed to fetch capacity:', payload);
          alert('Failed to fetch current capacity. Check console for details.');
        }
      });
    } catch (err) {
      console.error('fetchCurrentCapacity error:', err);
    }
  };

  // ------------------------------
  // Update the desired capacity (calls separate "update" Lambda)
  // ------------------------------
  const handleSubmitCapacity = async () => {
    console.log('Submitting desired capacity:', selectedCapacity);

    try {
      AWS.config.region = 'ap-southeast-2';
      const credentials = await Auth.currentCredentials();
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'ap-southeast-2:dab71613-f090-4601-b430-2dd3a76aec98',
        Logins: {
          'cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_G2MokH52O':
            (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
      });

      const lambda = new AWS.Lambda();

      const params = {
        FunctionName: 'AsseticOpsLambdasStack-production-update_asg_capacity', // <-- REPLACE with your "UPDATE" Lambda name
        InvocationType: 'RequestResponse',
        Payload: JSON.stringify({
          newDesiredCapacity: selectedCapacity,
          triggeredBy: userEmail, // user’s email
        }),
      };

      AWS.config.credentials.get(async (error) => {
        if (error) {
          console.error('Error retrieving credentials:', error);
          return;
        }
        const result = await lambda.invoke(params).promise();
        const payload = JSON.parse(result.Payload || '{}');

        if (payload.statusCode === 200) {
          alert(`Capacity updated to ${selectedCapacity}.`);

          // Optionally refetch the new capacity to update the UI
          fetchCurrentCapacity();
        } else {
          console.error('Lambda update error:', payload);
          alert('Failed to update capacity. See console for details.');
        }
      });
    } catch (error) {
      console.error('Lambda invocation failed:', error);
      alert('Failed to update capacity. See console for details.');
    }
  };

  // ------------------------------
  // Logout
  // ------------------------------
  const handleLogout = async () => {
    try {
      await Auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  // ------------------------------
  // Build array for the dropdown:
  // from (currentCapacity + 1) up to 12
  // ------------------------------
  let capacityOptions = [];
  if (currentCapacity !== null) {
    const start = Math.min(currentCapacity + 1, 12);
    for (let i = start; i <= 12; i++) {
      capacityOptions.push(i);
    }
  }

  return (
    <div>
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-light" style={{ backgroundColor: '#343a40' }}>
        <div className="container-fluid">
          <span className="navbar-brand" style={{ color: '#ffc107' }}>
            Admin Page
          </span>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#adminNavbar"
            aria-controls="adminNavbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="adminNavbar">
            <ul className="navbar-nav">
              {/* Link back to Dashboard */}
              <li className="nav-item">
                <Link className="nav-link" to="/dashboard" style={{ color: '#ffc107' }}>
                  Dashboard
                </Link>
              </li>
            </ul>

            <button
              className="btn btn-outline-danger ms-auto"
              style={{ color: '#ffc107' }}
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        </div>
      </nav>

      {/* Admin content */}
      <div className="container mt-5">
        <h3>Manage Desired Capacity For System Service Bus</h3>
        <p>Welcome, <strong>{userEmail}</strong>.</p>

        {currentCapacity === null ? (
          <p>Loading current capacity...</p>
        ) : (
          <>
            <p>Current Desired Capacity: {currentCapacity}</p>
            {capacityOptions.length === 0 ? (
              <p>Capacity is already at or above 12. No further increase possible.</p>
            ) : (
              <div>
                <div className="mb-3">
                  <label htmlFor="capacitySelect" className="form-label">
                    Increase Desired Capacity (up to 12):
                  </label>
                  <select
                    id="capacitySelect"
                    className="form-select custom-dropdown-size"
                    value={selectedCapacity}
                    onChange={(e) => setSelectedCapacity(e.target.value)}
                  >
                    <option value="">-- Select Capacity --</option>
                    {capacityOptions.map(num => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                </div>

                <button
                  className="btn btn-primary w-20"
                  disabled={!selectedCapacity}
                  onClick={handleSubmitCapacity}
                >
                  Submit
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default AdminPage;
