// AdminRoute.js
import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const AdminRoute = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const checkAdmin = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const groups = user.signInUserSession.idToken.payload["cognito:groups"] || [];
        setIsAdmin(groups.includes("DevOpsTeam")); // 'Admins' is your cognito user group name
      } catch (error) {
        // If we can't get the current user, likely not authenticated
        setIsAdmin(false);
      }
    };

    checkAdmin();
  }, []);

  if (isAdmin === null) {
    // Still checking auth state
    return <div>Loading...</div>;
  }

  if (!isAdmin) {
    // Not an admin: either redirect to Dashboard or show a Not Authorized page
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  return children;
};

export default AdminRoute;
